import React, {useState, useMemo, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from '../buttons/button';
import Banner from "../../imgs/bg_buniversity.jpg";
import Pagination from '../pagination/pagination';
import {Link} from "gatsby";
import DateSelect from "../dateSelect/dateSelect";
import './BalanzUniversity.css'
import { apiRequest } from "../../apiRquest/apiRequest";

const GlosarioList = ({ artdata }) => {
    const [arts, setArts] = useState([]);
    const [orderCriteria, setOrderCriteria] = useState("date");
    const [pageSize, setPageSize] = useState(8);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentArticlesData, setCurrentArticlesData] = useState([])
    const [artsData, setArtsData] = useState(artdata)
    let tempArts = [...artdata]
    tempArts.sort((a, b) => { return a.node.order - b.node.order } );
    let articulos2query = tempArts.slice(1,4)

    let options = [
        { name: "Más recientes", value: "date", className: "report-option" },
        { name: "Más vistos", value: "visits",className: "report-option" },
        { name: "Más relevantes", value: "order",className: "report-option" }
    ];

    const preparePage = ()=>{
        // console.log('prepare page')
        // console.log("arts", arts)
        if(arts.length > 0){
            const firstPageIndex = (currentPage - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            setCurrentArticlesData(arts.slice(firstPageIndex, lastPageIndex))
        }
    }

    const handleOrder = () => {
        // console.log('handle order', arts)
        const tempArts = [...arts]
        if(tempArts.length > 0){
            switch (orderCriteria){
                case 'date':
                    tempArts.sort((a, b) => { return b.timestamp - a.timestamp } );
                    break;
                case 'visits':
                    tempArts.sort((a, b) => { return b.visits - a.visits });
                    break;
                case 'order':
                    tempArts.sort((a, b) => { return b.order - a.order });
                    break;
                default:
                    tempArts.sort((a, b) => { return b.timestamp - a.timestamp } );
            }
            // console.log('tempArts', tempArts)
            setArts(tempArts)
            setCurrentPage(1)
        }
    };
    async function prepareArtsState(artsData){
        let dataSet = [];
        if (artsData.length > 0){
            artsData.forEach((item) => {
                // console.log('item', item)
                dataSet.push({
                    imgUrl: item.node.imgUrl,
                    date: item.node.date,
                    timestamp: new Date(item.node.timestamp),
                    title: item.node.title,
                    metatitle: item.node.metatitle,
                    order: item.node.order,
                    artid: item.node.artid,
                    visits: item.node.visits,
                    slug: item.node.slug,
                    description: item.node.description,
                    htmldesc: item.node.htmldesc,
                    category: item.node.category,
                    section: item.node.section,
                    htmlcontent: item.node.htmlcontent,
                });
            });
            // console.log('dataSet', dataSet)
            switch (orderCriteria){
                case 'date':
                    dataSet.sort((a, b) => { return b.timestamp - a.timestamp } );
                    // console.log('dataSet date1', dataSet)
                    break;
                case 'visits':
                    dataSet.sort((a, b) => { return b.visits - a.visits });
                    // console.log('dataSet visits', dataSet)
                    break;
                case 'order':
                    dataSet.sort((a, b) => { return b.order - a.order });
                    // console.log('dataSet order', dataSet)
                    break;
                default:
                    dataSet.sort((a, b) => { return b.timestamp - a.timestamp } );
                    // console.log('dataSet date2', dataSet)
            }

           // console.log('dataSet', dataSet)
            setArts(dataSet);
        }
    }

    //let pageSize = 8;
    useEffect(()=>{
        let active = true;
        prepareArtsState(artsData);
        apiRequest.get('/v1/university')
            .then(response => {
                const data = [];
                response.data.forEach(item => {
                    data.push({node: item})
                })
                setArtsData(data)

                setTimeout(()=>{
                    prepareArtsState(data)
                }, 1000);
            })
        return() => { active = false }
        
    },[])

    useEffect(()=>{
        preparePage()
    },[arts, currentPage])

    useEffect(()=>{
        // console.log('orderCriteria', orderCriteria)
        handleOrder();
    },[orderCriteria])

    useEffect(()=>{
        getReadingTime(articulos2query)
    },[])
    function getReadingTime(articulos2query) {
        let words3 = JSON.stringify(articulos2query)
       var words, time, minutes, seconds;
       words = words3.split(' ').length;
       time = (60 * words / 250)
       minutes = ~~(time / 60);
       // console.log(`minutos:${minutes}`)
       seconds = Math.ceil(time - minutes * 60);
       if(seconds >= 30) {
           return `${minutes+1}m`
       }
        else if(seconds < 30){
           return `${minutes}m`
       }
       
   }
 

    return (
        <>
           <section className="fullwidth articulos">
                <Container>
                    <Row className="justify-content-start g-3 g-md-3">
                        <h2><span className="smaller-title primary-color">Artículos de Educación Financiera </span> </h2>
                        <Col xs={12} className="mb-3 mb-md-5">
                            <DateSelect
                                options={options}
                                selectName={"order-select"}
                                controller={ setOrderCriteria }
                                className="report-select art-order-select ms-0 me-auto"
                                selected={orderCriteria}
                            />
                        </Col>
                        <Col xs={12}>
                            <Row className="investmentNewsContainer university-articles d-flex justify-content-center rowlist flex-wrap">
                                {


                                    currentArticlesData.map((item, index) => {
                                        //const imageData = item.image.childImageSharp.fluid;
                                        //const imageData = item.image.childImageSharp.fluid;
                                        //const imageUrl = item.image.publicURL
                                        const imageUrl = item.imgUrl
                                        return (
                                            <Col xs={12} md={6}
                                                className="newsCard basiflex-grow-0 flex-shrink-0"
                                                style={index === 0 ? { borderBottom: "none", flexBasis:"auto" } : { flexBasis:"auto"}}
                                                key={index}
                                            >
                                                {item.slug !== undefined ? (
                                                    <>
                                                <Link
                                                    to={`/balanz-university/${item.slug}/`}
                                                    className="picture ratio ratio-100x85"
                                                    style={{ backgroundImage: `url(${imageUrl})`}}
                                                ></Link>
                                                <Link
                                                    to={`/balanz-university/${item.slug}/`}
                                                    className="info">
                                                    {item.topic !== undefined && (
                                                        <div className="topic">{item.topic}</div>
                                                    )}
                                                    <h3>
                                                        <div className="text">{item.title}</div>
                                                    </h3>
                                                    {item.date !== undefined && (
                                                        <div className="date">{item.date} <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="css-1xgr03x e3ftz9k0"><path d="M11.9963 4C13.5785 4 15.1252 4.46919 16.4408 5.34824C17.7564 6.22729 18.7818 7.47672 19.3873 8.93853C19.9928 10.4003 20.1512 12.0089 19.8426 13.5607C19.5339 15.1126 18.772 16.538 17.6531 17.6569C16.5343 18.7757 15.1089 19.5376 13.557 19.8463C12.0052 20.155 10.3966 19.9965 8.93481 19.391C7.473 18.7855 6.22358 17.7602 5.34452 16.4446C4.46547 15.129 3.99628 13.5823 3.99628 12C3.99871 9.87901 4.84234 7.84559 6.34211 6.34583C7.84187 4.84606 9.87529 4.00243 11.9963 4ZM11.9963 2C10.0185 2 8.08507 2.58649 6.44058 3.6853C4.79609 4.78412 3.51436 6.3459 2.75749 8.17317C2.00061 10.0004 1.80258 12.0111 2.18843 13.9509C2.57428 15.8907 3.52669 17.6725 4.92521 19.0711C6.32374 20.4696 8.10557 21.422 10.0454 21.8079C11.9852 22.1937 13.9959 21.9957 15.8231 21.2388C17.6504 20.4819 19.2122 19.2002 20.311 17.5557C21.4098 15.9112 21.9963 13.9778 21.9963 12C21.9963 10.6868 21.7377 9.38641 21.2351 8.17315C20.7326 6.95988 19.996 5.85748 19.0674 4.92889C18.1388 4.0003 17.0364 3.26371 15.8231 2.76117C14.6099 2.25863 13.3095 1.99998 11.9963 2Z" fill="currentColor"></path><path d="M15.561 16.972L11.003 12.414V6.004H13.003V11.586L16.975 15.558L15.561 16.972Z" fill="currentColor"></path></svg>
                                                        {getReadingTime(item.htmlcontent)}
                                                        </div>
                                                    )}
                                                </Link>
                                                </>
                                                    ):(
                                                    <>
                                                        <div
                                                            className="picture ratio ratio-100x85"
                                                            style={{ backgroundImage: `url(${imageUrl})`}}
                                                        ></div>
                                                        <div className="info">
                                                            {item.topic !== undefined && (
                                                                <div className="topic">{item.topic}</div>
                                                            )}
                                                            <h3>
                                                                <div className="text">{item.title}</div>
                                                            </h3>
                                                            {item.date !== undefined && (
                                                                <div className="date">{item.date}</div>
                                                            )}
                                                        </div>
                                                    </>
                                                    )
                                                }
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                            <Row className="py-5 text-center">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={arts.length}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidthbg curso-university py-5">
                <Container className="d-flex align-items-stretch">
                    <Row className="d-flex justify-content-start align-content-center flex-grow-1 flex-shrink-1 gx-4 gx-md-5">
                        <Col xs={12} md={8} className="colum-university">
                            <div className="section-content">
                                <h2 className="titulo"><span className="tit-60">Aprendé todo lo que tenés que saber para invertir mejor y potenciar tus ahorros con nuestra formación.</span></h2>
                            </div>
                        </Col>
                        <Col xs={12} md={3} lg={4} className="d-flex align-items-center justify-content-start justify-content-lg-center">
                            <div className="text-left text-md-center pt-5 pb-0 py-md-4 py-lg-5 m-0">
                                <Button variant="secondary" text="Ver más" onClick={() => { window.open("https://balanzuniversity.com/", "_blank") }} />

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        

        </>
    );
}

export default GlosarioList;